import styled from "styled-components"

export const MainLayout = styled.div`
    padding: 1rem;
    height: 100%;
    display: flex;
    gap: 1rem;
`;

export const InnerLayout = styled.div`
    padding: 2rem 1.5rem;
    width: 100%;
`;